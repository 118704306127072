<template>
  <div id="homeText">AI Playground</div>
</template>

<script>
// Vue
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { onMounted, onUnmounted } from "@vue/runtime-dom";

export default {
  name: "PlayerInterface",
  components: {
  },
  setup() {
    const store = useStore();

    onMounted(() => {
    });

    onUnmounted(() => {
    });

  },
};
</script>




<style lang="scss">
@import "@/assets/styles/theming.scss";

#homeText {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12vw;
  text-transform: uppercase;
}
</style>
